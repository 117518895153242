<template>
  <v-container>
    <v-card class="box">
      <v-card-title>
        Error Occurred
      </v-card-title>
      <v-card-text>
        {{ $t("error.not_found") }}
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn depressed link to="/">
          {{ $t("nav.home") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-container>
</template>

<script>
import constant from "@/const.js";

export default {
  name: constant.ROUTER_TAG.NOT_FOUND,
}
</script>
